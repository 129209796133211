import { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import useSWR from 'swr';

import {
  EnumsNotificationFrequencyEmail,
  EnumsNotificationFrequencySlack,
  EnumsUserSettingsGroupKey,
  type GetNotificationSettingsParams,
  type ModelsNotificationFeatures,
  type ModelsNotificationSettings,
} from '@lp-lib/api-service-client/public';

import { useUserSettingsAnalytics } from '../../analytics/userSettings';
import { apiService } from '../../services/api-service';
import { type Option } from '../common/Utilities';
import { Loading } from '../Loading';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';

const SLACK_GAME_UPDATES_OPTIONS = [
  {
    label: 'Weekly',
    value: EnumsNotificationFrequencySlack.NotificationFrequencySlackWeekly,
  },
  {
    label: 'Monthly',
    value: EnumsNotificationFrequencySlack.NotificationFrequencySlackMonthly,
  },
  {
    label: "Don't Send to Slack",
    value: EnumsNotificationFrequencySlack.NotificationFrequencySlackNever,
  },
];

const EMAIL_GAME_UPDATES_OPTIONS = [
  {
    label: 'Weekly',
    value: EnumsNotificationFrequencyEmail.NotificationFrequencyEmailWeekly,
  },
  {
    label: 'Month',
    value: EnumsNotificationFrequencyEmail.NotificationFrequencyEmailMonthly,
  },
  {
    label: 'Quarter (every 3 months)',
    value: EnumsNotificationFrequencyEmail.NotificationFrequencyEmailQuarterly,
  },
  {
    label: "Don't Send to Email",
    value: EnumsNotificationFrequencyEmail.NotificationFrequencyEmailNever,
  },
];

function Section(props: { title: React.ReactNode; children: React.ReactNode }) {
  return (
    <section>
      <h3 className='text-sm font-bold'>{props.title}</h3>
      <div className='mt-5 flex flex-col gap-4'>{props.children}</div>
    </section>
  );
}

function Checkbox(props: {
  editing: boolean;
  name: keyof ModelsNotificationSettings;
  children: React.ReactNode;
}) {
  const { register } = useFormContext<ModelsNotificationSettings>();

  return (
    <label
      className={`flex items-start gap-2.5 ${
        props.editing ? 'cursor-pointer' : 'cursor-default'
      }`}
    >
      <input
        {...register(props.name)}
        type='checkbox'
        disabled={!props.editing}
        className='checkbox-dark w-5 h-5 mt-0.5 disabled:cursor-default flex-none'
      />
      {props.children}
    </label>
  );
}

function CheckGroup<
  K extends keyof Pick<
    ModelsNotificationSettings,
    'slackFrequency' | 'emailFrequency'
  >,
  V extends ModelsNotificationSettings[K]
>(props: { editing: boolean; name: K; options: Option<V>[] }) {
  const { register } = useFormContext<ModelsNotificationSettings>();

  return (
    <>
      {props.options.map((option) => (
        <label
          key={option.value}
          className={`flex items-center gap-2.5 ${
            props.editing ? 'cursor-pointer' : 'cursor-default'
          }`}
        >
          <input
            {...register(props.name)}
            type='radio'
            value={option.value}
            id={option.value}
            className='checkbox-dark w-5 h-5 disabled:cursor-default flex-none'
            disabled={!props.editing}
          />
          {option.label}
        </label>
      ))}
    </>
  );
}

export function useNotificationSettings(params: GetNotificationSettingsParams) {
  return useSWR(
    ['/users/settings/notifications', params],
    async () => (await apiService.notification.GetSettings(params)).data
  );
}

export function NotificationForm(props: {
  initEditing?: boolean;
  settings?: ModelsNotificationSettings;
  features: ModelsNotificationFeatures;
  onSubmit: (data: ModelsNotificationSettings) => Promise<void>;
}) {
  const { initEditing = false, settings, features, onSubmit } = props;

  const analytics = useUserSettingsAnalytics();
  const [editing, setEditing] = useState(initEditing);

  const formReturned = useForm<ModelsNotificationSettings>({
    defaultValues: {
      analyticsDigestSlack: settings?.analyticsDigestSlack ?? true,
      analyticsDigestEmail: settings?.analyticsDigestEmail ?? true,
      disabled: settings?.disabled ?? false,
      slackFrequency:
        settings?.slackFrequency ??
        EnumsNotificationFrequencySlack.NotificationFrequencySlackWeekly,
      emailFrequency:
        settings?.emailFrequency ??
        EnumsNotificationFrequencyEmail.NotificationFrequencyEmailMonthly,
      onboardingGuides: settings?.onboardingGuides ?? true,
      gameRecapsSlack: settings?.gameRecapsSlack ?? true,
      recognitionWeeklyReport: settings?.recognitionWeeklyReport ?? true,
      pendingPurchaseReminder: settings?.pendingPurchaseReminder ?? true,
    },
  });
  const { reset, handleSubmit, watch, formState } = formReturned;

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    reset(settings);
    setEditing(false);
  };

  const submit = handleSubmit(async (data: ModelsNotificationSettings) => {
    analytics.trackUserSettingsSaved(
      EnumsUserSettingsGroupKey.UserSettingsGroupKeyNotification
    );
    await onSubmit(data);
    setEditing(false);
  });

  useEffect(() => {
    const sub = watch((value, { name }) => {
      if (!name) return;

      analytics.trackUserSettingsModified({
        groupKey: EnumsUserSettingsGroupKey.UserSettingsGroupKeyNotification,
        field: name,
        value: value ? uncheckedIndexAccess_UNSAFE(value)[name] : undefined,
      });
    });

    return () => sub.unsubscribe();
  }, [watch, analytics]);

  return (
    <FormProvider {...formReturned}>
      <form className='text-white' onSubmit={submit}>
        <header>
          <div className='flex items-center'>
            <h2 className='text-base font-bold'>Manage Notifications</h2>
            {!editing && (
              <button
                type='button'
                className='ml-30 btn text-primary text-sms font-medium'
                onClick={handleEdit}
              >
                Edit
              </button>
            )}
          </div>
          <p className='mt-2.5 text-sms font-normal text-icon-gray'>
            You can manage which types of notifications you receive here.
          </p>
        </header>

        <main className='mt-10 flex flex-col gap-7.5'>
          {features.onboardingGuide && (
            <Section title='Welcome Guides'>
              <Checkbox editing={editing} name='onboardingGuides'>
                Send me getting started guides!
              </Checkbox>
            </Section>
          )}

          {features.analyticsDigest && (
            <Section title='Analytics Monthly Digest'>
              <Checkbox editing={editing} name='analyticsDigestSlack'>
                Slack notification
              </Checkbox>
              <Checkbox editing={editing} name='analyticsDigestEmail'>
                Email notification
              </Checkbox>
            </Section>
          )}

          {features.gameUpdates && (
            <Section title='Slack Game Updates'>
              <CheckGroup
                editing={editing}
                name='slackFrequency'
                options={SLACK_GAME_UPDATES_OPTIONS}
              />
            </Section>
          )}

          {features.gameRecaps && (
            <Section title='Slack Game Recaps'>
              <Checkbox editing={editing} name='gameRecapsSlack'>
                Send recaps after I play a game
              </Checkbox>
            </Section>
          )}

          {features.gameUpdates && (
            <Section title='Email Game Updates'>
              <CheckGroup
                editing={editing}
                name='emailFrequency'
                options={EMAIL_GAME_UPDATES_OPTIONS}
              />
            </Section>
          )}

          <Section title='Ice Cream Shout-outs Leaderboard Updates'>
            <Checkbox editing={editing} name='recognitionWeeklyReport'>
              Send leaderboard updates
            </Checkbox>
          </Section>

          <Section title='One-Time Purchase'>
            <Checkbox editing={editing} name='pendingPurchaseReminder'>
              Send me follow up emails about my purchase
            </Checkbox>
          </Section>

          <Section title='Remove me from all notifications'>
            <Checkbox editing={editing} name='disabled'>
              Check this box if you want to be removed from all notifications
            </Checkbox>
          </Section>
        </main>

        <div
          className={`mt-10 w-full flex gap-4 ${editing ? '' : 'invisible'}`}
        >
          <button
            type='button'
            onClick={handleCancel}
            className='btn-secondary w-33 h-10'
          >
            Cancel
          </button>

          <button
            type='submit'
            className='btn-primary w-33 h-10 flex justify-center items-center'
            disabled={formState.isSubmitting}
          >
            {formState.isSubmitting && (
              <Loading text='' containerClassName='mr-2' />
            )}
            Save
          </button>
        </div>
      </form>
    </FormProvider>
  );
}

export function Notifications(): JSX.Element {
  const { data, isLoading, mutate } = useNotificationSettings({});

  if (isLoading || !data)
    return (
      <div className='w-full h-full flex items-center justify-center'>
        <Loading text='' />
      </div>
    );

  const handleSubmit = async (data: ModelsNotificationSettings) => {
    await apiService.notification.UpdateSettings({
      value: data,
    });
    await mutate();
  };

  return (
    <div className='pl-5'>
      <NotificationForm
        settings={data?.settings}
        features={data.features}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
